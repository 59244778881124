<template>
  <v-card
    :class="{ 'new' : isCurrentStatusNew(item) || isCurrentStatusCreated(item) }"
    class="mx-auto lead-card"
  >
    <v-card-text class="d-flex col-12">
      <div
        class="col-10 pa-0"
        @click="openContactDetails(item)"
      >
        <v-card-title class="pt-0 pl-0 title">
          <template v-if="hasName(item)">
            {{ formatName(item) }}
          </template>
          <template v-else>
            <span>{{ item.email }} </span>
          </template>
          <v-tooltip
            v-if="isEmailConfirmedVisible"
            top
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="item.confirmedAt"
                color="success"
                style="font-size: 18px"
                v-on="on"
              >
                mdi-check-circle
              </v-icon>
              <v-icon
                v-else
                style="font-size: 18px"
                color="orange lighten-2"
                v-on="on"
              >
                mdi-clock
              </v-icon>
            </template>
            <span v-if="item.confirmedAt">{{ $t('leads.table.labels.confirmed') }}</span>
            <span v-else>{{ $t('leads.table.labels.not-confirmed') }}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-subtitle
          v-if="item.lastConversionAt && isLastConversionAtVisible"
          class="pl-0 subtitle"
        >
          {{ $t('lead.card.last-activity') }}: {{ formatDate(item.lastConversionAt, 'dateShort') }}
        </v-card-subtitle>
      </div>
      <div
        v-if="isAssignedUserVisible"
        class="col-2 pa-0 text-end"
      >
        <v-tooltip
          v-if="item.assignedUser"
          top
        >
          <template v-slot:activator="{ on }">
            <div
              style="min-width: 30px; text-align: end;"
              v-on="on"
            >
              <v-avatar
                :color="item.assignedUser.color"
                size="30"
                @click="showUsersForSelect = !showUsersForSelect"
              >
                <span class="white--text caption font-weight-bold">
                  {{ getInitials(item.assignedUser) }}
                </span>
              </v-avatar>
              <AssignLead
                v-if="showUsersForSelect"
                :item="item"
                :users="users"
                @assignUser="assignUser"
              />
            </div>
          </template>
          <span v-if="item.assignedUser">{{ formatName(item.assignedUser) }}</span>
        </v-tooltip>

        <v-tooltip
          v-else
          top
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-icon
                large
                color="grey lighten-2"
                @click="showUsersForSelect = !showUsersForSelect"
              >
                mdi-account-circle avatar
              </v-icon>
              <AssignLead
                v-if="showUsersForSelect"
                :item="item"
                :users="users"
                @assignUser="assignUser"
              />
            </div>
          </template>
          <span>{{ $t('assign-leads.leads-for-select-tooltip') }}</span>
        </v-tooltip>
      </div>
    </v-card-text>
    <v-card-text
      v-if="areConversionsVisible"
      style="padding-left: 5px; padding-top: 0; padding-bottom: 0;"
    >
      <div
        v-for="{ type, label, color } of conversionTypes"
        :key="type"
      >
        <v-tooltip
          top
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-chip
              v-if="showAction(item, type)"
              :color="isPropertyRequestUrgent(item,type) ? 'red lighten-4' : color"
              x-small
              class="ml-1 float-left mb-2"
              v-on="isPropertyRequestUrgent(item,type) ? on : {}"
            >
              {{ $t(label) }}
            </v-chip>
          </template>
          <span>{{ isPropertyRequestUrgent(item,type) ? $t('leads.table.tooltips.start-asap') :'' }}</span>
        </v-tooltip>
      </div>
    </v-card-text>
    <v-card-text
      v-if="areTagsVisible && item.tags && item.tags.length > 0"
      class="tag-chips"
    >
      <template>
        <v-chip
          v-for="(tag, index) in crmTags(item.tags)"
          :key="index"
          color="#F0F0F0"
          x-small
          class="ml-1 float-left mb-2"
        >
          {{ tag }}
        </v-chip>
      </template>
    </v-card-text>
    <v-card-text
      v-if="hasPropertyValue(item)"
      class="d-flex justify-start py-0 property-value"
    >
      <div
        v-if="isValuationVisible && getPropertyValue(item)"
        class="mr-2 pt-0"
      >
        <v-icon class="pa-1 property-value-icons">
          mdi mdi-home-analytics
        </v-icon>
        <span>{{ getPropertyValue(item) }}</span>
      </div>
      <div v-if="isCommissionVisible && getCommissionValue(item, commission)">
        <v-icon class="pa-1 property-value-icons">
          mdi mdi-percent
        </v-icon>
        <span>{{ getCommissionValue(item, commission) }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import currencyMixin from '@/mixins/currency'
import { isBasicUser, isLightUser } from '@/lib/productTypes'
import propertyRequestMixin from '@/modules/leads/mixins/propertyRequest'
import showActionMixin from '@/modules/leads/mixins/showAction'
import nameMixin from '@/modules/leads/mixins/name'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import AssignLead from '@/modules/leads/LeadList/AssignLead.vue'
import { crmTags } from '@/modules/leads/LeadList/lib/crmTags'
import { hasPropertyValue, propertyValue, commissionValue } from '@/modules/leads/LeadList/lib/property'

export default {
  components: { AssignLead },
  mixins: [currencyMixin, propertyRequestMixin, showActionMixin, nameMixin],
  props: {
    commission: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      default: () => []
    },
    conversionTypes: {
      type: Array,
      required: true
    },
    isLastConversionAtVisible: {
      type: Boolean,
      required: true
    },
    areConversionsVisible: {
      type: Boolean,
      required: true
    },
    isEmailConfirmedVisible: {
      type: Boolean,
      required: true
    },
    isAssignedUserVisible: {
      type: Boolean,
      required: true
    },
    areTagsVisible: {
      type: Boolean,
      required: true
    },
    isValuationVisible: {
      type: Boolean,
      required: true
    },
    isCommissionVisible: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      showUsersForSelect: false
    }
  },
  computed: {
    isBasicUser () {
      return isBasicUser(this.$auth.user)
    },
    isLightUser () {
      return isLightUser(this.$auth.user)
    }
  },
  methods: {
    hasPropertyValue,
    crmTags,
    getPropertyValue (item) {
      return propertyValue(item, this.formatPrice)
    },

    getCommissionValue (item, commission) {
      return commissionValue(item, commission, this.formatPrice)
    },
    async assignUser (userForSelect, id) {
      if (userForSelect.value === this.item?.assignedUser?.id) {
        showSnackbarMessage('error', this.$t('alerts.assign-leads.already-assigned', { userName: userForSelect.text }))
        return
      }

      this.showUsersForSelect = false
      this.$emit('assignUser', id, userForSelect)
    },
    openContactDetails (contact) {
      const routeData = this.$router.resolve({
        name: 'lead-details',
        params: {
          id: contact.id
        }
      })

      if (window.self !== window.top) {
        // Running inside an iframe (e.g. in the CRM)
        this.$router.push(routeData.href)
      } else {
        window.open(routeData.href, '_blank')
      }
    },
    isCurrentStatusNew (item) {
      return item.currentStatus === 'new'
    },
    isCurrentStatusCreated (item) {
      return item.currentStatus === 'created'
    }
  }
}
</script>

<style scoped>
.lead-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px !important;
  box-shadow: none !important;
  background: #FFFFFF;
  max-width: 400px;
}

.lead-card:hover {
  box-shadow: 5px 5px 10px 0 rgba(20, 19, 30, 0.15) !important;
}

.title {
  color: #252525;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.subtitle {
  color: #252525;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.new {
  border-top: 4px solid #E41D1D;
}

.tag-chips {
  padding: 0 0 0 5px;
}

.property-value {
  padding-left: 5px;
  margin-bottom: 5px;
  font-weight: 700;
}

.property-value span {
  font-size: 12px;
}

.property-value-icons {
  font-size: 16px;
}
</style>
