<template>
  <v-card
    class="mx-auto users-for-select"
  >
    <v-card-text
      v-if="isBasicUser || isLightUser"
      class="text-center"
    >
      {{ $t('assign-leads.info') }}<br>
      <a href="/settings/contract">{{ $t('assign-leads.upgrade') }}</a>
    </v-card-text>
    <v-list
      v-else
      density="compact"
    >
      <v-list-item
        v-for="(userForSelect, i) in users"
        :key="i"
        :value="userForSelect.value"
        color="primary"
        @click="assignUser(userForSelect, item.id)"
      >
        <v-avatar
          :color="userForSelect.color"
          class="mr-2 "
          size="30"
        >
          <span class="white--text caption font-weight-bold">
            {{ getInitials( {
              firstname: userForSelect.firstName,
              lastname: userForSelect.lastName })
            }}
          </span>
        </v-avatar><br>
        <v-list-item-title
          class="text-start"
          v-text="userForSelect.text"
        />
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script >
import { isBasicUser, isLightUser } from '@/lib/productTypes'
import nameMixin from '@/modules/leads/mixins/name'

export default {
  mixins: [nameMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isBasicUser () {
      return isBasicUser(this.$auth.user)
    },
    isLightUser () {
      return isLightUser(this.$auth.user)
    }
  },
  methods: {
    async assignUser (userForSelect, id) {
      this.$emit('assignUser', userForSelect, id)
    }
  }
}
</script>

<style scoped>
.users-for-select {
  display: flex;
  width: 260px;
  max-width: 300px;
  max-height: 280px;
  overflow: auto;
  position: absolute;
  z-index: 9;
  right: 0;
  padding: 8px 0;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 5px 5px 10px 0 rgba(20, 19, 30, 0.15) !important;
  border-radius: 4px !important;
  background: #FFFFFF;

}

::v-deep .theme--light.v-list-item:hover::before {
  opacity: 0;
}

::v-deep .theme--light.v-list-item:focus::before {
  opacity: 0;
}
</style>
